import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';

import IconArrowLeft from 'client/icons/ChevronLeft';
import IconArrowRight from 'client/icons/ChevronRight';

import { ArrowLeft, ArrowRight, Container, Slide, Slides, CarouselPropertiesBase } from './styles';
import { updatedBreakpoint } from 'client/styles';
import { DEFAULT_ITEMS_PER_SLIDE } from './constants';
import { PageContext } from 'src/comps/Page/context';
import { PropertySources } from '../Molecules/PropertyCard';

const CarouselProperties = ({
  children: renderFn,
  items = [],
  isAutoHeight = false,
  limitPerView = DEFAULT_ITEMS_PER_SLIDE,
  source = '',
}) => {
  const limitItemsPerSlide = useMemo(() => {
    return {
      ...DEFAULT_ITEMS_PER_SLIDE,
      ...limitPerView,
      ...(source === PropertySources.Visited ? { desktop: 4, tablet: 3 } : {}),
    };
  }, [limitPerView, source]);

  const { device } = useContext(PageContext);
  const isTabletOrWider = useMedia(updatedBreakpoint.tablet, device !== 'mobile');
  const isDesktop = useMedia(updatedBreakpoint.desktop, device === 'desktop');

  const [currentSlide, setCurrentSlide] = useState(0);
  const containerRef = useRef(null);

  const itemsPerView = useMemo(() => {
    if (isTabletOrWider) {
      return isDesktop ? limitItemsPerSlide.desktop : limitItemsPerSlide.tablet;
    }
    return limitItemsPerSlide.mobile;
  }, [isTabletOrWider, isDesktop, limitItemsPerSlide]);

  useEffect(() => {
    if (containerRef.current && isTabletOrWider) {
      containerRef.current.scrollLeft = 0;
    }
  }, [containerRef, isTabletOrWider]);

  useEffect(() => {
    if (items.length <= currentSlide * itemsPerView) {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    }
  }, [items, setCurrentSlide, itemsPerView]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCarouselSlides = useMemo(
    () =>
      items?.map((item, index) => (
        <Slide
          key={item.id}
          data-testid="slide-property"
          isAutoHeight={isAutoHeight}
          itemsPerView={itemsPerView}
        >
          {renderFn({ item, index })}
        </Slide>
      )),
    [items, renderFn, isAutoHeight, itemsPerView]
  );

  if (!items || items.length === 0 || !renderFn) {
    return null;
  }

  const maxSlides = isTabletOrWider
    ? Math.ceil(items.length / (isDesktop ? limitItemsPerSlide.desktop : limitItemsPerSlide.tablet))
    : null;

  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === maxSlides - 1 || currentSlide === items.length - 1;

  function prevSlide(event) {
    event.preventDefault();
    return !isFirstSlide && setCurrentSlide(currentSlide - 1);
  }

  function nextSlide(event) {
    event.preventDefault();
    return !isLastSlide && setCurrentSlide(currentSlide + 1);
  }

  return (
    <CarouselPropertiesBase isWide={isDesktop}>
      {isTabletOrWider && !isFirstSlide && (
        <ArrowLeft onClick={prevSlide}>
          <IconArrowLeft />
        </ArrowLeft>
      )}

      <Container ref={containerRef} isWide={isTabletOrWider}>
        <Slides current={currentSlide} slides={maxSlides || items.length}>
          {renderCarouselSlides}
        </Slides>
      </Container>

      {isTabletOrWider && !isLastSlide && (
        <ArrowRight onClick={nextSlide}>
          <IconArrowRight />
        </ArrowRight>
      )}
    </CarouselPropertiesBase>
  );
};

CarouselProperties.propTypes = {
  children: PropTypes.func,
  items: PropTypes.instanceOf(Array),
  isAutoHeight: PropTypes.bool,
  limitPerView: PropTypes.shape({
    desktop: PropTypes.number,
    tablet: PropTypes.number,
  }),
  source: PropTypes.string,
};

export default CarouselProperties;
