import styled from 'styled-components';
import { colors, updatedMedia } from 'client/styles';
import { Info, PriceWrapper, PropertyWrapper } from 'src/comps/Property/styles';

export const CarouselPropertiesBase = styled.div`
  ${Info} {
    padding: 12px;
    flex: 1 1 auto;
    justify-content: space-between;

    ${PriceWrapper} {
      align-items: flex-end;
    }
  }

  position: relative;
`;

export const Container = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
  ${updatedMedia.tablet} {
    overflow-x: hidden;
  }
`;

export const Slides = styled.div`
  display: flex;
  transition: transform 0.25s ease-in-out;

  ${updatedMedia.tablet} {
    transform: ${props => `translate(calc(${props.current} * -100%))`};
  }
`;

export const Slide = styled.div`
  flex: 0 0 auto;
  padding: 10px;
  max-width: ${props => (props.itemsPerView > 0 ? `calc(100% / ${props.itemsPerView})` : '275px')};
  ${props => props.isAutoHeight && 'display: flex'};

  ${updatedMedia.tablet} {
    width: 100%;
  }

  ${PropertyWrapper} {
    height: 100%;
  }
`;

const ArrowBase = styled.span`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 50%;
  border: 2px solid ${colors.grey4};
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: absolute;
  width: 44px;
  z-index: 9;
  top: 50%;
  transform: translate(0, -50%);

  &:hover,
  &:active {
    border-color: ${colors.grey1};
  }
  svg {
    fill: ${colors.grey1};
  }
`;

export const ArrowLeft = styled(ArrowBase)`
  left: -18px;
`;

export const ArrowRight = styled(ArrowBase)`
  right: -18px;
`;
