import React from 'react';
import { FormattedMessage } from 'react-intl';

import CarouselProperties from 'src/comps/CarouselProperties';
import Loader from 'src/comps/Atom/Loader';
import Property from 'src/comps/Property';
import { PropertyCard, PropertyCardVariants } from 'src/comps/Molecules/PropertyCard';
import { usePage } from 'src/hooks/usePage';
import { useKafkaEvent } from 'src/hooks/useKafkaEvent';
import { SECTIONS } from 'src/hooks/useKafkaEvent/constants';
import { CardContainer, Content, PropertiesBase, Title } from './styles';
import messages from './messages';

import { Properties as PropertiesType } from 'src/pages/api/trpc/banner-properties/schema';
import styles from './styles.module.css';

export type sourceType =
  | 'default'
  | 'visited'
  | 'recommended'
  | 'related'
  | 'map'
  | 'results'
  | 'hotelsSeen'
  | 'blackFriday'
  | 'newYearDeals'
  | 'springSale'
  | 'promos';

export type titleIdType = keyof typeof messages;
export type messageType = (typeof messages)[titleIdType];

interface PropertiesProps {
  source?: sourceType;
  titleId?: titleIdType | messageType;
  properties: PropertiesType | [];
  isLoading?: boolean;
  action?: (args: { propertyId: number }) => void;
  position?: number;
}

const Properties = React.forwardRef<HTMLElement, PropertiesProps>(
  (
    {
      source = 'default',
      titleId,
      properties = [],
      isLoading = false,
      position,
      action = () => ({}),
    },
    ref
  ) => {
    const isCardExperimentActive = true;

    const { pageId } = usePage();
    const section = source === 'related' ? SECTIONS.RELATED : SECTIONS.HOTELS_SEEN;
    const carousel = isCardExperimentActive ? 'carousel_css' : 'carousel_photos';

    const titleMessage = typeof titleId === 'string' ? { ...messages?.[titleId] } : titleId;

    const { sendEvent: sendKafkaClick } = useKafkaEvent({ page: pageId });
    const { sendEvent: sendKafkaSwipe } = useKafkaEvent({
      eventName: 'swipe',
      page: pageId,
    });

    if (!isLoading && properties.length === 0) {
      return null;
    }

    function handleClick(id: number, pos: number) {
      sendKafkaClick({ section }, { id_property: id, pos });
    }

    function handleSwipeEvent(slide: number, idProperty: number) {
      sendKafkaSwipe({ section }, { slide_index: slide, carousel, id_property: idProperty });
    }

    return (
      <PropertiesBase
        ref={ref}
        source={source}
        className="carousel"
        data-tracking-carousel="properties"
        data-tracking-carousel-position={position}
      >
        <Content source={source}>
          {titleMessage && source !== 'hotelsSeen' && (
            <Title source={source}>
              <FormattedMessage {...titleMessage} />
            </Title>
          )}

          {isLoading ? (
            <Loader />
          ) : (
            <CarouselProperties items={properties} source={source}>
              {({ item: property, index }) => {
                return isCardExperimentActive ? (
                  <CardContainer>
                    <PropertyCard
                      {...property}
                      key={property.id}
                      onRemove={action}
                      position={index + 1}
                      onClick={() => handleClick(property.id, index + 1)}
                      source={source}
                      className={source === 'related' ? styles.minHeight : ''}
                      variant={
                        ['related', 'visited', 'hotelsSeen'].includes(source)
                          ? PropertyCardVariants.fixedHeight
                          : PropertyCardVariants.default
                      }
                      prime={property.isPrime}
                      handleSwipeEvent={(slide: number) => handleSwipeEvent(slide, property.id)}
                    />
                  </CardContainer>
                ) : (
                  <Property
                    {...property}
                    key={property.id}
                    lazyLoad
                    parentAction={action}
                    position={index + 1}
                    source={source}
                    prime={property.isPrime}
                    handleSwipeEvent={(slide: number) => handleSwipeEvent(slide, property.id)}
                  />
                );
              }}
            </CarouselProperties>
          )}
        </Content>
      </PropertiesBase>
    );
  }
);

export default Properties;
