import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { routes } from 'src/constants/routes';
import { useMarket } from 'src/hooks/useMarket';
import Properties, { messageType, sourceType, titleIdType } from 'src/comps/Properties';
import { PropertySources } from 'src/comps/Molecules/PropertyCard';

import { fetchPropertiesVisitedRequestAction, removePropertyAction } from './actions';
import { getIsFetching, getProperties } from './selectors';
import { usePage } from 'src/hooks/usePage';
import { getPropertiesIdsFromCookie, updatePropertiesCookie } from './utils';
import styles from './styles.module.css';

interface PropertiesVisited {
  source?: sourceType;
  titleId?: titleIdType | messageType;
  setShowHoteslSeen: (value: boolean) => void;
}

const PropertiesVisitedContainer = ({
  source = `${PropertySources.HotelsSeen}`,
  titleId,
  setShowHoteslSeen,
}: PropertiesVisited) => {
  const dispatch = useDispatch();
  const { language } = useMarket();
  const { pageId } = usePage();

  const isLoading = useSelector(getIsFetching);
  const properties = useSelector(getProperties);

  const isUserProfilePage = pageId === routes.hotelsSeen.page;

  useEffect(() => {
    const propertiesIds = getPropertiesIdsFromCookie();

    if (propertiesIds && propertiesIds.length > 0) {
      dispatch(fetchPropertiesVisitedRequestAction({ propertiesIds, language }));
    }
  }, [dispatch, language]);

  function removeProperty({ propertyId }: { propertyId: number }) {
    dispatch(removePropertyAction({ propertyId }));
    updatePropertiesCookie({ propertyId, isDelete: true });

    const propertiesIds = getPropertiesIdsFromCookie();

    if (propertiesIds.length === 0 && typeof setShowHoteslSeen === 'function') {
      setShowHoteslSeen(false);
    }
  }

  const showTitleBySource = [
    `${PropertySources.HotelsSeen}`,
    `${PropertySources.Visited}`,
  ].includes(source);

  return (
    <>
      {showTitleBySource && properties.length > 0 && (
        <div
          className={cn(styles.titleContainer, {
            [styles.titlePadding]: source === `${PropertySources.HotelsSeen}`,
          })}
        >
          <h6 className={styles.title}>
            <FormattedMessage id="global.hotels-seen" />
          </h6>
        </div>
      )}

      <div className={cn({ [styles.fixMargin]: source === `${PropertySources.Visited}` })}>
        <Properties
          action={removeProperty}
          isLoading={isLoading}
          properties={properties}
          source={source}
          titleId={titleId}
        />
      </div>

      {properties.length === 0 && isUserProfilePage && (
        <div className={styles.messageContainer}>
          <FormattedMessage id="global.no-hotels-seen" />
        </div>
      )}
    </>
  );
};

export default PropertiesVisitedContainer;
