import { defineMessages } from 'react-intl';

const messages = defineMessages({
  relatedTitle: {
    id: 'related.title',
  },
  seeMore: {
    id: 'related.see-more',
  },
  ourSelection: {
    id: 'promo.our-selection',
  },
  keepOnViewing: {
    id: 'global.keep-on-viewing',
  },
  hotelsSeen: {
    id: 'global.hotels-seen',
  },
});

export default messages;
