import styled from 'styled-components';
import { colors, updatedMedia } from 'client/styles';
import { sourceType } from '.';

function getPadding(source: sourceType) {
  switch (source) {
    case 'related':
      return '40px 0 20px 0';
    case 'visited':
      return '0px';
    default:
      return '6px 0';
  }
}

export const PropertiesBase = styled.section<{ source: sourceType }>`
  background-color: transparent;
  padding: ${props => getPadding(props.source)};

  ${updatedMedia.tablet} {
    padding: ${props =>
      props.source === 'hotelsSeen' || props.source === 'visited' ? `0px` : `40px 0 40px`};
  }
`;

export const Content = styled.div<{ source: sourceType }>`
  ${updatedMedia.tablet} {
    margin: ${props =>
      props.source === 'hotelsSeen' || props.source === 'recommended' || props.source === 'visited'
        ? `0px`
        : `0 30px`};
  }

  ${updatedMedia.desktop} {
    margin: 0 auto;
    padding: ${props =>
      props.source === 'hotelsSeen' || props.source === 'recommended' || props.source === 'visited'
        ? `0px`
        : `0 30px`};
    max-width: 1160px;
  }
`;

export const CardContainer = styled.div`
  ${updatedMedia.onlyMobile} {
    width: 320px;
    max-width: 100%;
  }
`;

export const Title = styled.h5<{ source: sourceType }>`
  align-items: center;
  color: ${colors.grey1};
  display: flex;
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  margin: 0 20px 6px 10px;
  max-width: 1080px;

  ${updatedMedia.tablet} {
    margin: 0 10px 6px;
    font-size: 28px;
    line-height: 34px;
  }
`;
